import { PageComponent as Hero } from "src/molecules/Hero";
import TheGutLifePreview from "src/organisms/TheGutLifePreview";
import { PageComponent as PictureCarousel } from "src/molecules/PictureCarousel";
import { PageComponent as CardCarousel } from "src/molecules/CardCarousel";
import { PageComponent as FeaturedPostCarousel } from "src/molecules/FeaturedPostCarousel";
import { PageComponent as InfluencerPush } from "src/molecules/InfluencerPush";
import EditorialPush from "src/molecules/EditorialPush";
import EditorialPushList from "src/organisms/EditorialPushList";
import { PageComponent as Cta, TYPES as LINK_TYPES } from "src/atoms/Link";
import { PageComponent as RichText } from "src/atoms/RichText";
import { PageComponent as ProductPush } from "src/molecules/ProductPush";
import { PageComponent as Illustration } from "src/atoms/Illustration";
import { PageComponent as VideoPush } from "src/molecules/VideoPush";
import { PageComponent as QuestionAnswerList } from "src/organisms/QuestionAnswerList";
import { PageComponent as Share } from "src/molecules/Share";
import { PageComponent as CodeEmbed } from "src/atoms/CodeEmbed";
import { PageComponent as ImagePush } from "src/molecules/ImagePush";
import { PageComponent as RetailerPush } from "src/molecules/RetailerPush";
import { PageComponent as RecipeDetails } from "src/molecules/RecipeDetails";
import { PageComponent as InstagramImagesCarousel } from "src/molecules/InstagramImagesCarousel";
import { PageComponent as MediaAndText } from "src/molecules/MediaAndText";
import { PageComponent as Popup } from "src/molecules/Popup";
import { PageComponent as LinkNavigation } from "src/organisms/LinkNavigation";
import { PageComponent as ProductFlavorsCardsList } from "src/organisms/ProductFlavorsCardsList";

import TwoColumns from "src/molecules/TwoColumns";
import { PageComponent as Tracker } from "src/molecules/Tracker";
import TrackerCategory from "src/molecules/Tracker/TrackerCategories";
import TrackerForm from "src/molecules/TrackerForm";
import Spacer from "./Spacer";

import { TYPES as PICTURE_TYPES } from "src/atoms/Picture";
import getImage from "src/utils/getImage";

export default {
  ContentfulHero: {
    Component: Hero,
    mapper: ({
      position,
      title,
      subtext,
      media,
      cta,
      ctaList,
      youtubeVideoId,
      videoPlayButtonStyle,
      overlayOptions,
    }) => {
      return {
        position,
        title,
        subtext,
        image: getImage(media.large, PICTURE_TYPES.fluid),
        imageMobile: getImage(media.small, PICTURE_TYPES.fluid),
        useOverlay: !overlayOptions || overlayOptions[0] !== "Remove overlay",
        cta,
        ctaList,
        video: youtubeVideoId,
        videoPlayButtonStyle,
      };
    },
  },
  ContentfulCta: {
    Component: Cta,
    mapper: ({
      label,
      buttonStyle,
      icon,
      target,
      title,
      image,
      url,
      clickScript,
      mediaToDownload,
      usePopup,
      popupId,
      closePopup,
    }) => ({
      label,
      buttonStyle,
      icon,
      target,
      title,
      image: image && getImage(image, PICTURE_TYPES.fluid),
      url,
      clickScript,
      mediaToDownload,
      usePopup,
      popupId,
      closePopup,
      type: LINK_TYPES.button,
    }),
  },
  ContentfulTheGutLifePreview: {
    Component: TheGutLifePreview,
    mapper: ({
      hint,
      title,
      backgroundStyle,
      cta: { label, url, buttonStyle },
      fill,
      cards,
    }) => ({
      welcome: {
        hint,
        title,
        showCta: !!(label && url),
        label: label,
        url: url,
        buttonStyle: buttonStyle,
      },
      backgroundStyle,
      fill,
      cards: cards || [], // mapping of this attr takes place in Card, differs per card type
    }),
  },
  ContentfulPictureCarousel: {
    Component: PictureCarousel,
    mapper: ({ pictures }) => ({
      pictures: pictures.map((picture) =>
        getImage(picture, PICTURE_TYPES.fixed)
      ),
    }),
  },
  ContentfulCardCarousel: {
    Component: CardCarousel,
    mapper: ({ title, cards, highlightedCardConfiguration }) => ({
      title,
      cards,
      highlightSecond:
        !highlightedCardConfiguration ||
        highlightedCardConfiguration[0] !== "Prevent auto highlight",
    }),
  },
  ContentfulFeaturedPostCarousel: {
    Component: FeaturedPostCarousel,
    mapper: ({ items }) => ({
      items: items.map(({ title, subtitle, picture, video }) => ({
        title,
        subtitle,
        picture: picture && getImage(picture, PICTURE_TYPES.fluid),
        video: video && {
          cloudinaryVideo:
            video && video.cloudinaryVideo && video.cloudinaryVideo[0],
          mobileCloudinaryVideo:
            video &&
            video.cloudinaryMobileVideo &&
            video.cloudinaryMobileVideo[0],
          videoCover:
            video &&
            video.cloudinaryVideoCover &&
            getImage(video.cloudinaryVideoCover, PICTURE_TYPES.fluid),
          videoCoverMobile:
            video &&
            video.cloudinaryVideoCoverMobile &&
            getImage(video.cloudinaryVideoCoverMobile, PICTURE_TYPES.fluid),
        },
        isVideo: !picture,
      })),
    }),
  },
  ContentfulInfluencerPush: {
    Component: InfluencerPush,
    mapper: ({
      title,
      description,
      countryName,
      countryFlag,
      instagramUrl,
      stat1Name,
      stat1Value,
      stat2Name,
      stat2Value,
      stat3Name,
      stat3Value,
    }) => ({
      title,
      description,
      country: {
        name: countryName,
        flag: getImage(countryFlag, PICTURE_TYPES.fixed),
      },
      instagramUrl,
      instagramStats: [
        {
          label: stat1Name || "",
          value: stat1Value || "",
        },
        {
          label: stat2Name || "",
          value: stat2Value || "",
        },
        {
          label: stat3Name || "",
          value: stat3Value || "",
        },
      ].filter(({ label, value }) => label.length && value.length),
    }),
  },
  ContentfulEditorialPush: {
    Component: EditorialPush,
    mapper: ({ cardCarousel, text, ...rest }) => {
      return {
        background: false,
        text: text.value,
        cards: cardCarousel.cards,
        standalone: true,
      };
    },
  },
  ContentfulEditorialPushList: {
    Component: EditorialPushList,
    mapper: ({ pushes }) => {
      return {
        items: pushes.map(({ greyBackground, cardCarousel, text }) => ({
          background: greyBackground,
          doc: text.value,
          cards: cardCarousel.cards,
        })),
      };
    },
  },
  ContentfulTextModule: {
    Component: RichText,
    mapper: ({ value, alignment }) => ({
      doc: value,
      alignment: alignment || "left",
    }),
  },
  ContentfulProductPush: {
    Component: ProductPush,
    mapper: ({ titleInternal, desc, image, flavor }) => ({
      title: titleInternal,
      description: desc,
      image: getImage(image, PICTURE_TYPES.fluid),
      flavor,
    }),
  },
  ContentfulIllustration: {
    Component: Illustration,
    mapper: ({ name }) => {
      return {
        name,
      };
    },
  },
  ContentfulVideo: {
    Component: VideoPush,
    mapper: ({
      cloudinaryVideo,
      cloudinaryMobileVideo,
      cloudinaryVideoCover,
      cloudinaryVideoCoverMobile,
    }) => {
      return {
        cloudinaryVideo: cloudinaryVideo && cloudinaryVideo[0],
        mobileCloudinaryVideo:
          cloudinaryMobileVideo && cloudinaryMobileVideo[0],
        videoCover: getImage(cloudinaryVideoCover, PICTURE_TYPES.fluid),
        videoCoverMobile: getImage(
          cloudinaryVideoCoverMobile,
          PICTURE_TYPES.fluid
        ),
      };
    },
  },
  ContentfulQuestionAnswerList: {
    Component: QuestionAnswerList,
    mapper: ({
      title,
      titlePosition,
      collapsible,
      collapsibleStyle,
      items,
      ctaList,
    }) => ({
      title,
      titlePosition,
      collapsible,
      collapsibleStyle,
      items: items.map(({ id, question, answer, topics }) => ({
        id,
        question,
        answer,
        topics:
          topics &&
          topics.map(({ id, name }) => ({
            id,
            name,
          })),
      })),
      ctaList,
    }),
  },
  ContentfulTwoColumns: {
    Component: TwoColumns,
    mapper: ({ left, right }) => ({
      left: left.map(({ value }) => value),
      leftAlignment: left.map(({ alignment }) => alignment)[0],
      right: right.map(({ value }) => value),
      rightAlignment: right.map(({ alignment }) => alignment)[0],
    }),
  },
  ContentfulSpacer: {
    Component: Spacer,
    mapper: (props) => props,
  },
  ContentfulShareModule: {
    Component: Share,
    mapper: ({
      title,
      enableFacebook,
      enableTwitter,
      enableCopyToClipboard,
      enableWhatsapp,
      enableEmail,
      twitterText,
    }) => ({
      title,
      enableFacebook,
      enableTwitter,
      enableWhatsapp,
      enableEmail,
      enableCopy: enableCopyToClipboard,
      twitterCopy: twitterText,
    }),
  },
  ContentfulCodeEmbed: {
    Component: CodeEmbed,
    mapper: ({ embeddedCode, fullWidth }) => ({
      embedCode: embeddedCode.embeddedCode,
      fullWidth,
    }),
  },
  ContentfulImagePush: {
    Component: ImagePush,
    mapper: ({
      imageDesktop,
      imageMobile,
      fixedImageWidth,
      isPictureStyled,
      innerText,
      link,
      ctaText,
    }) => ({
      image: getImage(imageDesktop, PICTURE_TYPES.fluid),
      imageMobile: getImage(imageMobile, PICTURE_TYPES.fluid),
      fixedImageWidth,
      isPictureStyled,
      title: innerText,
      url: link,
      cta: ctaText &&
        link && {
          label: ctaText,
          title: ctaText,
          url: link,
        },
    }),
  },
  ContentfulRetailerPush: {
    Component: RetailerPush,
    mapper: ({ title, image, retailers }) => ({
      title,
      image: image && getImage(image, PICTURE_TYPES.fluid),
      retailers: retailers.map((retailer) => ({
        ...retailer,
        logo: getImage(retailer.logo, PICTURE_TYPES.fluid),
      })),
    }),
  },
  ContentfulRecipeDetails: {
    Component: RecipeDetails,
    mapper: ({
      title,
      description,
      servingsLabel,
      servingsAmount,
      servingSizeLabel,
      servingSizeAmount,
      preparationLabel,
      preparationTimeInMinutes,
      cookingLabel,
      cookingTimeInMinutes,
      minutesLabel,
      ingredientsLabel,
      ingredientsList,
      calories,
      protein,
      carbohydrates,
      fats,
      step1Title,
      step1Image,
      step1Description,
      step2Title,
      step2Image,
      step2Description,
      step3Title,
      step3Image,
      step3Description,
      step4Title,
      step4Image,
      step4Description,
      step5Title,
      step5Image,
      step5Description,
      step6Title,
      step6Image,
      step6Description,
      step7Title,
      step7Image,
      step7Description,
      step8Title,
      step8Image,
      step8Description,
      step9Title,
      step9Image,
      step9Description,
      step10Title,
      step10Image,
      step10Description,
      createdAt,
    }) => {
      const steps = [];

      if (step1Title && step1Description) {
        steps.push({
          title: step1Title,
          image: step1Image && getImage(step1Image, PICTURE_TYPES.fluid),
          description: {
            doc: step1Description.value,
            alignment: step1Description.alignment || "left",
          },
        });
      }
      if (step2Title && step2Description) {
        steps.push({
          title: step2Title,
          image: step2Image && getImage(step2Image, PICTURE_TYPES.fluid),
          description: {
            doc: step2Description.value,
            alignment: step2Description.alignment || "left",
          },
        });
      }
      if (step3Title && step3Description) {
        steps.push({
          title: step3Title,
          image: step3Image && getImage(step3Image, PICTURE_TYPES.fluid),
          description: {
            doc: step3Description.value,
            alignment: step3Description.alignment || "left",
          },
        });
      }
      if (step4Title && step4Description) {
        steps.push({
          title: step4Title,
          image: step4Image && getImage(step4Image, PICTURE_TYPES.fluid),
          description: {
            doc: step4Description.value,
            alignment: step4Description.alignment || "left",
          },
        });
      }
      if (step5Title && step5Description) {
        steps.push({
          title: step5Title,
          image: step5Image && getImage(step5Image, PICTURE_TYPES.fluid),
          description: {
            doc: step5Description.value,
            alignment: step5Description.alignment || "left",
          },
        });
      }
      if (step6Title && step6Description) {
        steps.push({
          title: step6Title,
          image: step6Image && getImage(step6Image, PICTURE_TYPES.fluid),
          description: {
            doc: step6Description.value,
            alignment: step6Description.alignment || "left",
          },
        });
      }
      if (step7Title && step7Description) {
        steps.push({
          title: step7Title,
          image: step7Image && getImage(step7Image, PICTURE_TYPES.fluid),
          description: {
            doc: step7Description.value,
            alignment: step7Description.alignment || "left",
          },
        });
      }
      if (step8Title && step8Description) {
        steps.push({
          title: step8Title,
          image: step8Image && getImage(step8Image, PICTURE_TYPES.fluid),
          description: {
            doc: step8Description.value,
            alignment: step8Description.alignment || "left",
          },
        });
      }
      if (step9Title && step9Description) {
        steps.push({
          title: step9Title,
          image: step9Image && getImage(step9Image, PICTURE_TYPES.fluid),
          description: {
            doc: step9Description.value,
            alignment: step9Description.alignment || "left",
          },
        });
      }
      if (step10Title && step10Description) {
        steps.push({
          title: step10Title,
          image: step10Image && getImage(step10Image, PICTURE_TYPES.fluid),
          description: {
            doc: step10Description.value,
            alignment: step10Description.alignment || "left",
          },
        });
      }

      return {
        title,
        description: description.description,
        servingsLabel,
        servingsAmount,
        servingSizeLabel,
        servingSizeAmount,
        preparationLabel,
        preparationTimeInMinutes: parseFloat(preparationTimeInMinutes),
        cookingLabel,
        cookingTimeInMinutes: parseFloat(cookingTimeInMinutes),
        minutesLabel,
        ingredientsLabel,
        ingredientsList,
        calories,
        protein,
        carbohydrates,
        fats,
        steps,
        createdAt,
      };
    },
  },
  ContentfulInstagramImagesCarousel: {
    Component: InstagramImagesCarousel,
    mapper: ({ title, autoplay, autoplayTransitionTime, instagramPosts }) => {
      return {
        title,
        autoplay,
        autoplayTransitionTime,
        instagramPosts,
      };
    },
  },
  ContentfulMediaAndText: {
    Component: MediaAndText,
    mapper: ({
      sideMedia,
      youtubeVideoId,
      sideMediaTitle,
      sideText,
      ctaList,
      mediaAndTextAlignment,
      styleOptions,
    }) => {
      return {
        image: getImage(sideMedia.large, PICTURE_TYPES.fluid),
        imageMobile: getImage(sideMedia.small, PICTURE_TYPES.fluid),
        youtubeVideoId,
        sideMediaTitle,
        sideText,
        ctaList,
        mediaAndTextAlignment,
        isFullWidth:
          !!styleOptions && styleOptions[0] === "Full width background style",
      };
    },
  },
  ContentfulPopup: {
    Component: Popup,
    mapper: ({
      popupId,
      popupContent,
      confirmationText,
      showCloseButton,
      acceptHcpCta,
    }) => {
      return {
        popupId,
        popupContent,
        confirmationText,
        showCloseButton,
        acceptHcpCta,
      };
    },
  },
  ContentfulTracker: {
    Component: Tracker,
    mapper: ({
      id,
      title,
      trackerDescription,
      image,
      trackingStartCode,
      generalTrackingAreaCode,
      categoryTrackingEndValue,
      introductionSlideText,
      introductionSlideImage,
      trackerForm,
      categories,
      thanksSlideText,
      thanksSlideImage,
      trackerResultPageTitle,
      slug,
      showResultsTrackingCode,
    }) => {
      return {
        id,
        title,
        trackerDescription,
        imageDesktop: getImage(image.large, PICTURE_TYPES.fluid),
        imageMobile: getImage(image.small, PICTURE_TYPES.fluid),
        trackingStartCode,
        generalTrackingAreaCode,
        categoryTrackingEndValue,
        introductionSlideText,
        introductionSlideImageDesktop: getImage(
          introductionSlideImage.large,
          PICTURE_TYPES.fluid
        ),
        introductionSlideImageMobile: getImage(
          introductionSlideImage.small,
          PICTURE_TYPES.fluid
        ),
        trackerForm,
        categories,
        thanksSlideText,
        thanksSlideImageDesktop: getImage(
          thanksSlideImage.large,
          PICTURE_TYPES.fluid
        ),
        thanksSlideImageMobile: getImage(
          thanksSlideImage.small,
          PICTURE_TYPES.fluid
        ),
        showResultsTrackingCode,
        trackerResultPageTitle: trackerResultPageTitle,
        resultPageSlug: slug,
      };
    },
  },
  ContentfulTrackerForm: {
    Component: TrackerForm,
    mapper: ({
      id,
      formSlideText,
      formConsent,
      formPosition,
      optionalForm,
    }) => {
      return {
        id,
        formSlideText,
        formConsent,
        formPosition,
        optionalForm,
      };
    },
  },
  ContentfulTrackerCategory: {
    Component: TrackerCategory,
    mapper: ({
      id,
      title,
      subtitle,
      description,
      image,
      icon,
      backgroundColor,
      slug,
      categoryTrackingValue,
    }) => {
      return {
        id,
        title,
        subtitle,
        description,
        image,
        icon,
        backgroundColor,
        slug,
        categoryTrackingValue,
      };
    },
  },
  ContentfulLinkNavigation: {
    Component: LinkNavigation,
    mapper: ({ ctaList }) => {
      return {
        ctaList,
      };
    },
  },
  ContentfulProductFlavorsCardsList: {
    Component: ProductFlavorsCardsList,
    mapper: ({ title, cardsListSubtext, categoriesList }) => {
      return {
        title,
        subheadline: cardsListSubtext.cardsListSubtext,
        categoriesList,
      };
    },
  },
};
