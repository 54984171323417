import React from "react";
import PropTypes from "prop-types";

import CardRaw from "./CardRaw";
import { SHAPES, ICONS, TYPES } from "./constants";
import { getSlugForFlavor } from "src/utils/slugger";
import { TYPES as PICTURE_TYPES } from "src/atoms/Picture";
import getImage from "src/utils/getImage";

const Card = React.memo(
  ({ __typename = TYPES.blank, className, trackPosition, ...rest }) => {
    // this object should handle the various Contentful shapes
    // and mangle the data into a normalised shape for the
    // CardRaw component to display
    const cardFields = {
      title: rest.cardTitle,
      category: rest.category,
      image: rest.cardImage
        ? getImage(rest.cardImage, PICTURE_TYPES.fixed)
        : undefined,
      cardHighlighted: rest.cardHighlighted,
      slug: rest.slug,
      loadAnimated: rest.loadAnimated,
    };
    let props;
    const flavorCategory =
      rest.subcategory && rest.subcategory[0].category[0].title;

    switch (__typename) {
      case TYPES.editorialPhotography:
        props = {
          ...cardFields,
          icon: ICONS.article,
          shape: SHAPES.scoop,
        };
        break;
      case TYPES.editorialIllustration:
        props = {
          ...cardFields,
          icon: ICONS.fact,
          shape: SHAPES.layered,
          green: true,
        };
        break;
      case TYPES.influencer:
        props = {
          ...cardFields,
          icon: ICONS.video,
          shape: SHAPES.layered,
        };
        break;
      case TYPES.podcast:
        props = {
          ...cardFields,
          icon: ICONS.podcast,
          shape: SHAPES.circle,
        };
        break;
      case TYPES.recipe:
        props = {
          ...cardFields,
          icon: ICONS.recipe,
          shape: SHAPES.circle,
        };
        break;
      case TYPES.product:
        props = {
          ...cardFields,
          icon: null,
          shape: SHAPES.plain,
          slug: getSlugForFlavor(rest),
          image: rest.image
            ? getImage(rest.image, PICTURE_TYPES.fixed)
            : undefined,
          category: {
            name: flavorCategory,
          },
        };
        break;
      case TYPES.fact:
        props = {
          shape: SHAPES.fact,
          title: rest.frontTitle,
          fact: rest.backTitle,
          illustration: rest.illustration.name,
          category: rest.category,
          loadAnimated: rest.loadAnimated,
          disclaimer: rest.legalMention,
        };
        break;
      case TYPES.trackerCategory:
        props = {
          shape: SHAPES.trackerCategory,
          slug: rest.slug,
          title: rest.title,
          backgroundColor: rest.backgroundColor,
          percent: rest.percent,
          icon: rest.icon,
          carryState: rest.carryState,
        };
        break;
      case TYPES.blank:
        props = {
          cardHighlighted: rest.cardHighlighted,
          loadAnimated: rest.loadAnimated,
        };
        break;
      case TYPES.interactive:
        props = {
          ...cardFields,
          type: __typename,
          title: rest.title,
          cardText: rest.cardText.cardText,
          cardHighlighted: rest.cardHighlighted,
          popupTitle: rest.popupTitle,
          popupText: rest.popupText.popupText,
          popupImage: rest.popupImage
            ? getImage(rest.popupImage, PICTURE_TYPES.fixed)
            : undefined,
          id: rest.id,
          cta: rest.cta,
        };
        break;
    }

    return (
      <CardRaw className={className} {...props} trackPosition={trackPosition} />
    );
  }
);

Card.propTypes = {
  __typename: PropTypes.oneOf(Object.values(TYPES)),
  className: PropTypes.string,
  trackPosition: PropTypes.func,
};

export default Card;
