export default (trackerStep, trackerArea) => {
  console.log("trackerStep", trackerStep);
  console.log("trackerArea", trackerArea);
  if (typeof window.tC.event.tracker_microbiota === "function") {
    window.tC.event.tracker_microbiota(this, {
      tracker_step: trackerStep,
      tracker_area: trackerArea,
    });
  }
};
